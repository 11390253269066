import { AbstractResource, Methods } from '../AbstractResource';

export default class SendPublicInvitation extends AbstractResource<string> {
    private vanityUrl: string = '';

    protected noAuthentication: boolean = true;

    protected getAllowedMethods(): Methods[] {
        return [Methods.POST];
    }

    protected getPath(): string {
        return `/invite-api/${this.vanityUrl}`;
    }

    public sendInvitation(vanityUrl, data: SendPublicInvitationParamsType): Promise<string> {
        this.vanityUrl = vanityUrl;

        return this.post(data);
    }
}

export type SendPublicInvitationParamsType = {
    firstName: string;
    lastName: string;
    email: string;
    captcha: string;
    menteeType: string;
}
