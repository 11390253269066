import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { ErrorType, CustomAction as Action } from '@plumtreesystems/utils';
import componentsControl from '@/modules/ComponentsControls';
import afterRegistration from '@/modules/SendRegistration/afterRegistration';
import { INVITATION_SENT_SUCCESSFULLY } from '@/utils/messages/formValidation';
import { RegistrationFormType } from './types';
import { initialRegistrationData } from './defaults';
import { FORM_ERROR_TOOLTIP } from '../constants';
import registrationFormValidation from './services/registrationFormValidation';
import PublicRepository from './services/publicRepository';
import { ObjectPropertyType } from '../types';

@Module({
    namespaced: true, dynamic: true, store, name: 'sendRegistration',
})
@AutoMutations
export class SendRegistration extends VuexModule {
    private formData: RegistrationFormType = initialRegistrationData();

    private formErrors: ErrorType = {};

    private loadingInBackground: boolean = false;

    private displayTooltip: boolean = false;

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public resetData() {
        this.formData = initialRegistrationData();
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors = {
            ...this.formErrors,
            [payload.key]: payload.val,
        };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public clearFormData() {
        this.formData = { ...initialRegistrationData() };
    }

    @Action()
    public validateInvitationForm() {
        this.clearFormErrors();
        const formErrors = registrationFormValidation(this.formData);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async inviteAmbassador(data: { vanityUrl: string, menteeType: string }) {
        try {
            this.validateInvitationForm();
            const { vanityUrl, menteeType } = data;

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoadingInBackground(true);
                await PublicRepository
                    .publicInviteAmbassador({ ...this.formData, menteeType }, vanityUrl);

                componentsControl.showSuccessMessage({ message: INVITATION_SENT_SUCCESSFULLY });
                afterRegistration.setEmail(this.formData.email);
                this.resetData();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            throw e;
        } finally {
            this.setLoadingInBackground(false);
        }
    }
}

export default getModule(SendRegistration);
