
import { Vue, Component } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import impersonate from '@/modules/Impersonation';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import system from '@/modules/System';
import StatusBar from '@/projectComponents/statusBar/index.vue';
import NavItem from './Item/index.vue';
import Section from './Section/index.vue';

@Component({
    components: {
        NavItem,
        Section,
        StatusBar,
    },
})
export default class Navigation extends Vue {
    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    @Get(impersonate) private token!: string|null;

    @Get(system) private screenType!: string;

    @Get(profile) private shopLoginUrl!: string;

    get isDevelopmentEnvironment(): boolean {
        return env.VUE_APP_DEV === 'true';
    }

    get isDesktop() {
        return this.screenType === 'desktop';
    }

    get impersonating(): boolean {
        return !!this.token;
    }

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    get iconBaseUrl() {
        return `${env.BASE_URL}img/icons/`;
    }

    get shopBaseUrl() {
        return env.VUE_APP_SHOP_URL;
    }

    get websiteUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=customer/partner`;
    }

    get websiteSettingsUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=customer/partner/settings`;
    }

    get myReorderRequestsUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=customer/partner_reorder/history`;
    }

    get myHeldOrdersUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=heldorder/partner/index`;
    }

    get myOrdersUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=sales/order/history/`;
    }

    get orderNowUrl() {
        return `${this.shopBaseUrl}/gb/cognito/auth/directLogin?redirect-path=books/latest-titles`;
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }
}
