
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { BaseOrderEnrolleeType, BaseOrderType } from '@/api/graphQL/graphNodes/types';
import profile from '@/modules/Profile';
import CurrencyPrefix from '@/utils/currencyPrefix';
import myCustomers from '@/modules/MyCustomers';
import eventView from '@/modules/Event/EventView';
import auth from '@/modules/Auth';
import env from '@/environment';
import system from '@/modules/System';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import OrderDetails from './OrderDetails/index.vue';

@Component({
    components: {
        OrderDetails,
    },
})
export default class EventOrdersView extends Vue {
    @Prop({ required: true }) private orders!: BaseOrderType[];

    @Prop({ required: true }) private cancelled!: boolean;

    @Prop({ required: true }) private closed!: boolean;

    @Prop({ required: true }) private eventId!: string;

    @Get(eventView) private showOrderDetails!: boolean;

    @Get(profile, 'data.region') private region!: string;

    @Get(eventView) private showHostessBonusDetails!: boolean;

    @Get(system) private screenType!: string;

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get shopUrl(): string {
        if (this.isAmbassador) {
            return `${env.VUE_APP_SHOP_URL}/gb/cognito/auth/directLogin?redirect-path=/gb/uevent?event=${this.eventId}`;
        }

        return `${env.VUE_APP_SHOP_URL}/gb/uevent?event=${this.eventId}`;
    }

    get ordersTotalValue(): string {
        return this.orders
            .reduce((total, order) => total + Number(order.value), 0)
            .toFixed(2);
    }

    get ordersTableClasses() {
        return [
            { EventOrdersView__HostessOrderItem: this.isHostess },
        ];
    }

    getCustomerFullName(customer: null|BaseOrderEnrolleeType) {
        if (customer === null) {
            return '-';
        }

        return `${customer.firstName} ${customer.lastName}`;
    }

    beforeDestroy() {
        eventView.setShowOrderDetails(false);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get ordersTotalCommisionable() {
        return this.orders
            .reduce((total, order) => total + Number(order.commissionableValue), 0)
            .toFixed(2);
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    handleShowOrderDetails(order: BaseOrderType) {
        if (!this.isHostess) {
            myCustomers.setProvideOrderDetails(order);
            eventView.toggleShowOrderDetails();
        }
    }

    handleDialogClose() {
        eventView.toggleShowOrderDetails();
    }

    handleBonusDetailsOpen() {
        eventView.setShowHostessBonusDetails(true);
    }

    handleBonusDetailsClose() {
        eventView.setShowHostessBonusDetails(false);
    }

    mounted() {
        eventView.setDisplayCustomerDetails(false);
        eventView.setShowHostessBonusDetails(false);
    }
}
