
import {
    Component, Vue, Watch,
} from 'vue-property-decorator';
import resize from 'vue-resize-directive';
import InstallApp from '@/views/AppInstall/index.vue';
import Navigation from '@/views/Navigation/index.vue';
import StatusBar from '@/projectComponents/statusBar/index.vue';
import authModule from '@/modules/Auth/index';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import componentsControls from '@/modules/ComponentsControls';
import impersonationControls from '@/modules/Impersonation/controls';
import init from '@/serviceInit';
import { BrowserDetector } from '@plumtreesystems/utils';
import Header from '@/projectComponents/header/index.vue';
import { RouteType } from './modules/System/types';
import settings, { UserSettings, APP_SHOWN_STORAGE_KEY } from './modules/Settings';
import LocalStorageManager from './utils/local-storage-manager';
import ScrollButton from './projectComponents/scrollButton/index.vue';

init();
@Component({
    components: {
        Navigation,
        InstallApp,
        StatusBar,
        ScrollButton,
        Header,
    },
    directives: {
        resize,
    },
})
export default class App extends Vue {
    @Get(authModule) authenticationInProgress!: boolean;

    @Get(authModule) myAccountLoading!: boolean;

    @Get(authModule) suspended!: boolean;

    @Get(impersonationControls, 'loading') impersonationLoading!: boolean;

    @Get(settings) userSettings!: UserSettings;

    @Get(system) screenType!: string;

    @Get(componentsControls) drawerOpened!: boolean;

    private suspensionCheck = 0;

    get appClasses() {
        return [
            { App__RegisterRoute: ['register', 'hostessRegistration'].includes(this.$route.name || '') },
        ];
    }

    get displayButtons() {
        return !this.myAccountLoading && this.displayNav && !this.impersonationLoading;
    }

    get dontShowInstallApp(): boolean {
        return [
            'auth', 'register', 'registerAdditionalDetails', 'sendRegistration', 'afterRegistration',
            'hostessRegistration', 'eventInvite', 'eventInvitationView', 'customerRegistration',
        ].includes(this.$route.name || '');
    }

    get loadedContent() {
        if (
            [
                'login', 'auth', 'register', 'registerAdditionalDetails', 'sendRegistration', 'afterRegistration',
                'hostessRegistration', 'eventInvite', 'eventInvitationView', 'customerRegistration',
            ].includes(this.$route.name || '')) {
            return true;
        }
        return !this.myAccountLoading
            && !this.authenticationInProgress
            && !this.impersonationLoading;
    }

    get displayNav() {
        return ![
            'login', 'logout', 'auth', 'authError', 'error', 'noPermission',
            'register', 'registerAdditionalDetails', 'sendRegistration', 'afterRegistration',
            'hostessRegistration', 'eventInvite', 'eventInvitationView', 'customerRegistration',
        ].includes(this.$route.name || '');
    }

    get isDrawerOpen() {
        if (this.screenType !== 'desktop') {
            return this.drawerOpened;
        }
        return true;
    }

    get isContentActive() {
        if (this.screenType !== 'desktop') {
            return !this.drawerOpened;
        }
        return true;
    }

    get drawerStyle() {
        return {
            visibility: this.isDrawerOpen ? 'visible' : 'hidden',
        };
    }

    @Watch('$route')
    onRouteChange(route) {
        system.setRoute(route);
    }

    @Watch('suspended')
    handleSuspensionChange() {
        this.handleSuspensions();
    }

    handleSuspensions() {
        if (this.suspended) {
            this.suspensionCheck = setInterval(() => {
                authModule.checkSuspension();
            }, 30000);
        } else {
            clearInterval(this.suspensionCheck);
        }
    }

    beforeMount() {
        system.setRoute(this.$route as RouteType);
    }

    mounted() {
        if (BrowserDetector.isSafari) {
            if (
                // @ts-ignore
                !window.navigator.standalone
                && LocalStorageManager.getStorage(APP_SHOWN_STORAGE_KEY) !== 'true'
                && BrowserDetector.isMobileDevice()
            ) {
                settings.setInstallReady(true);
            }
        }

        this.handleSuspensions();
    }

    setSize(container: HTMLElement) {
        system.handleScreenChange(container);
    }
}
