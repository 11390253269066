
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import sendRegistration from '@/modules/SendRegistration';
import { ErrorType } from '@plumtreesystems/utils';
import { RegistrationFormType } from '@/modules/SendRegistration/types';
import EmailInvite from '@/projectComponents/emailInvite/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';
import auth from '@/modules/Auth';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import InvalidSponsor from './invalidSponsor/index.vue';

@Component({
    components: {
        EmailInvite,
        InvalidSponsor,
    },
})
export default class SendRegistration extends Vue {
    @Sync(sendRegistration) private formData!: RegistrationFormType;

    @Get(sendRegistration) private loadingInBackground!: boolean;

    @Get(sendRegistration) private formErrors!: ErrorType;

    @Get(sendRegistration) private displayTooltip!: boolean;

    private invalidSponsor: boolean = false;

    mounted() {
        sendRegistration.clearFormData();
        sendRegistration.clearFormErrors();

        if (auth.isHostess) {
            const token = authenticator.authTokenFromStorage || '';
            this.$router.push({ name: 'register', query: { token } });
            return;
        }

        if (auth.isAmbassador) {
            this.$router.push({ name: 'dashboard' });
        }
    }

    beforeDestroy() {
        sendRegistration.setDisplayTooltip(false);
    }

    get vanityUrl() {
        return this.$route.params.vanityUrl.toString();
    }

    async handleSendInvite() {
        try {
            const { vanityUrl } = this;
            const { type } = this.$route.query;
            const formattedType = type && typeof type === 'string' ? type.toLowerCase() : '';

            await sendRegistration.inviteAmbassador({
                vanityUrl,
                menteeType: formattedType === 'cold' || formattedType === 'hot' ? formattedType : '',
            });

            const query: any = {};

            if (formattedType !== '') {
                query.type = formattedType;
            }

            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'afterRegistration', params: { vanityUrl }, query });
            }
        } catch (e) {
            const error = ErrorsProcessor.process(e);

            if (error.form.INVALID_SPONSOR) {
                this.invalidSponsor = true;
            }

            console.error(e);
        }
    }

    handleLoginRedirect() {
        authenticator.logout(true);
    }
}

