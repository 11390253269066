
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import afterRegistration from '@/modules/SendRegistration/afterRegistration';
import authenticator from '@/modules/Auth/services/authenticator';
import env from '@/environment';

@Component
export default class AfterRegistration extends Vue {
    @Get(afterRegistration) email!: string;

    private secondsLeft: number = 0;

    get vanityUrl() {
        return this.$route.params.vanityUrl.toString();
    }

    get mocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get siteKey() {
        return env.VUE_APP_RECAPTCHA_SITE_KEY;
    }

    inviteMore() {
        const { vanityUrl } = this;
        this.$router.push({ name: 'sendRegistration', params: { vanityUrl }, query: this.$route.query });
    }

    async send() {
        this.countDown();

        if (!this.mocked) {
            await this.$recaptchaLoaded();

            const token = await this.$recaptcha('login');

            afterRegistration.resendRegistration(token);
        } else {
            afterRegistration.resendRegistration();
        }
    }

    countDown(sec = 60) {
        const next = sec - 1;
        this.secondsLeft = next;

        if (next > 0) {
            setTimeout(() => {
                this.countDown(next);
            }, 1000);
        }
    }

    handleLoginRedirect() {
        authenticator.logout(true);
    }
}

