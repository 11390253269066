
import { BUTTON_DELAY_TIME } from '@/modules/constants';
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class Dialog extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ default: false }) private openDialog!: boolean;

    @Prop({ default: false }) private disableActions!: boolean;

    @Prop({ default: false }) private disableSubmit!: boolean;

    @Prop({ default: false }) private disableClose!: boolean;

    @Prop({ default: 'primary' }) private closeButtonStyle!: string;

    @Prop({ default: 'Close' }) private closeButtonText!: string;

    @Prop({ default: 'text' }) private closeButtonType!: string;

    @Prop({ default: 'primary' }) private submitButtonStyle!: string;

    @Prop({ default: 'Submit' }) private submitButtonText!: string;

    @Prop({ default: 'contained' }) private submitButtonType!: string;

    @Prop({ default: '' }) private contentClass!: string;

    @Prop({ default: false }) private loading!: boolean;

    @Prop({ default: true }) private closeWithEsc!: boolean;

    private isOpened: boolean = false;

    get elementClasses() {
        return [
            'Dialog__Container',
            { 'Dialog__Container--close': !this.openDialog },
            { 'Dialog__Container--open': this.isOpened },
        ];
    }

    get contentClasses() {
        return [
            'Dialog__Content',
            this.contentClass,
        ];
    }

    handleClose() {
        setTimeout(() => {
            this.$emit('close');
        }, BUTTON_DELAY_TIME);
    }

    handleSubmit() {
        setTimeout(() => {
            this.$emit('submit');
        }, BUTTON_DELAY_TIME);
    }

    handleCloseWithEsc() {
        if (this.closeWithEsc) {
            this.$emit('close');
        }
    }

    handleBeacon(open: boolean) {
        const elList = document.getElementsByClassName('BeaconFabButtonFrame');

        if (elList.length < 1) {
            return;
        }

        const el = elList[0];

        if (open) {
            el.classList.add('BeaconFabButtonFrame--moved');
        } else {
            el.classList.remove('BeaconFabButtonFrame--moved');
        }
    }

    @Watch('openDialog')
    onDialogOpen() {
        const { openDialog } = this;

        this.handleBeacon(openDialog);
        if (openDialog) {
            this.isOpened = true;
            this.$nextTick(() => {
                ((this.$refs.dialogButtons as HTMLElement)
                    .lastChild as HTMLElement)
                    .focus();
            });
        } else {
            setTimeout(() => {
                this.isOpened = false;
            }, BUTTON_DELAY_TIME);
        }
    }
}
